import React from 'react';
import { Link } from "react-router-dom";
import backpackWithShell from '../images/backpack-with-shell.jpg';
import caminoArrow from '../images/caminoarrow.png';

const Loading = ({ from, to }) => {
  return (
    <div className="theme-hero-area theme-hero-area-full">
      <div className="theme-hero-area-bg-wrap">
        <div className="theme-hero-area-bg" style={{backgroundImage: `url(${backpackWithShell})`}}></div>
        <div className="theme-hero-area-mask theme-hero-area-mask-strong"></div>
        <div className="theme-hero-area-inner-shadow"></div>
      </div>
      <div className="theme-hero-area-body theme-hero-area-body-vert-center">
        <div className="container">
          <div className="theme-loading">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="spinner theme-loading-spinner">
                  <div className="spinner-grid-dots">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div className="theme-loading-body">
                  <h2 className="theme-loading-title">Just a moment</h2>
                  <p className="theme-loading-subtitle">We are looking for routes from
                    <br/>
                    <b>{from}</b>
                    <br />
                    to <b>{to}</b>

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link className="theme-abs-logo" to="/">
        <img src={caminoArrow} />
      </Link>
    </div>
  );
};

export default Loading;