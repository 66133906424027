import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

import {
  faFacebook,
  faInstagram,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";
import backgroundImage from "../images/backpacking.jpg";
import NavBar from "../components/NavBar";
import LocationSelector from "../components/LocationSelector";

const Home = ({
  departureLocation,
  setDepartureLocation,
  arrivalLocation,
  setArrivalLocation,
  departureDate,
  setDepartureDate,
  numPassengers,
  setNumPassengers,
  locations,
  currentUser
}) => {
  const [goToSearch, setGoToSearch] = useState(false);
  const onSearch = () => {
    if (departureLocation && arrivalLocation && numPassengers && departureDate.isValid()) {
      localStorage.setItem('numPassengers', numPassengers);
      localStorage.setItem('arrivalLocation', arrivalLocation.id);
      localStorage.setItem('departureLocation', departureLocation.id);
      localStorage.setItem('departureDate', moment(departureDate).format('YYYY-MM-DD'));
      setGoToSearch(true);
    }
  }

  return (
    <Fragment>
      {goToSearch && <Redirect to="/search" />}
      <NavBar currentUser={currentUser} currentPage="home" />
      <div className="_pos-r">
        <div className="theme-hero-area _h-desk-100vh">
          <div className="theme-hero-area-bg-wrap">
            <div
              className="theme-hero-area-bg"
              style={{ backgroundImage: `url(${backgroundImage})` }}
              id="hero-banner"
            />
            <div className="theme-hero-area-mask theme-hero-area-mask-half" />
            <div className="theme-hero-area-inner-shadow" />
          </div>
          <div className="theme-hero-area-body _pos-desk-v-c _w-f _pv-mob-60">
            <div className="container">
              <div className="row">
                <div className="col-md-11 ">
                  <div className="theme-search-area theme-search-area-stacked theme-search-area-white">
                    <div className="theme-search-area-header _mb-20">
                      <h1 className="theme-search-area-title">
                        Direct routes to the <b>Camino de Santiago</b>
                      </h1>
                      <p className="theme-search-area-subtitle">
                        Search hundreds of cheap routes to St. Jean Pied de
                        Port, Roncesvalles, & Sarria.
                      </p>
                    </div>
                    <div
                      className="theme-search-area-form"
                      id="hero-search-form"
                    >
                      <div className="row" data-gutter="none">
                        <div className="col-md-6 ">
                          <div className="row" data-gutter="none">
                            <div className="col-md-6 ">
                              <div className="theme-search-area-section first theme-search-area-section-curved">
                                <div className="theme-search-area-section-inner">
                                  <i className="theme-search-area-section-icon lin lin-location-pin" />
                                  <LocationSelector
                                    placeholder="Departure"
                                    selectedLocation={departureLocation}
                                    setSelectedLocation={setDepartureLocation}
                                    locations={locations}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="theme-search-area-section theme-search-area-section-curved">
                                <div className="theme-search-area-section-inner">
                                  <i className="theme-search-area-section-icon lin lin-location-pin" />
                                  <LocationSelector
                                    placeholder="Destination"
                                    selectedLocation={arrivalLocation}
                                    setSelectedLocation={setArrivalLocation}
                                    locations={locations}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 ">
                          <div className="row" data-gutter="none">
                            <div className="col-md-9 ">
                              <div className="theme-search-area-section theme-search-area-section-curved">
                                <div className="theme-search-area-section-inner">
                                  <i className="theme-search-area-section-icon lin lin-calendar" />
                                  <input
                                    onChange={({ target }) =>
                                      setDepartureDate(moment(target.value))
                                    }
                                    value={departureDate.format("YYYY-MM-DD")}
                                    className="theme-search-area-section-input datePickerStart _mob-h"
                                    type="date"
                                    min={moment().format('YYYY-MM-DD')}
                                  />
                                  <input
                                    onChange={({ target }) =>
                                      setDepartureDate(moment(target.value))
                                    }
                                    value={departureDate.format("YYYY-MM-DD")}
                                    className="theme-search-area-section-input _desk-h mobile-picker"
                                    type="date"
                                    min={moment().format('YYYY-MM-DD')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div
                                className="theme-search-area-section theme-search-area-section-curved quantity-selector"
                                data-increment="Passengers"
                              >
                                <div className="theme-search-area-section-inner">
                                  <i className="theme-search-area-section-icon lin lin-people" />
                                  <input
                                    className="theme-search-area-section-input"
                                    value={numPassengers}
                                    onChange={({ target }) =>
                                      setNumPassengers(target.value)
                                    }
                                    type="number"
                                    minLength="1"
                                    min="1"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <div onClick={onSearch}>
                            <button className="theme-search-area-submit _mt-0 theme-search-area-submit-glow theme-search-area-submit-curved">
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                className="theme-search-area-submit-icon"
                              />
                              <span className="_desk-h">Search</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="_pt-60">
                    <div className="row row-col-mob-gap">
                      <div className="col-md-3 ">
                        <div className="feature">
                          <div className="feature-icon feature-icon-white feature-icon-box feature-icon-round feature-icon-xs">
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                          <div className="feature-caption _c-w">
                            <h5 className="feature-title">Camino Family</h5>
                            <p className="feature-subtitle _op-04">
                              All Camino Bus employees have walked the camino.
                              Feel free to ask us any questions
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 ">
                        <div className="feature">
                          <div className="feature-icon feature-icon-white feature-icon-box feature-icon-round feature-icon-xs">
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                          <div className="feature-caption _c-w">
                            <h5 className="feature-title">All direct routes</h5>
                            <p className="feature-subtitle _op-04">
                              All routes are direct to make your journey as
                              quick and simple as possible.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 ">
                        <div className="feature">
                          <div className="feature-icon feature-icon-white feature-icon-box feature-icon-round feature-icon-xs">
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                          <div className="feature-caption _c-w">
                            <h5 className="feature-title">Airport pickup</h5>
                            <p className="feature-subtitle _op-04">
                              Just get off the airplane? We depart from all
                              major airports as well as city centers!
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 ">
                        <div className="feature">
                          <div className="feature-icon feature-icon-white feature-icon-box feature-icon-round feature-icon-xs">
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                          <div className="feature-caption _c-w">
                            <h5 className="feature-title">24/7 support</h5>
                            <p className="feature-subtitle _op-04">
                              Have questions? Need camino advice? We'd love to
                              help. <Link style={{ color: '#fff', fontWeight: '600' }} to="/contact">Send us a message.</Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme-footer-abs">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <ul className="theme-footer-abs-nav">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
                <p className="theme-footer-abs-copyright">
                  Copyright &copy; 2019 &nbsp;
                  <Link to="/">CaminoBus.com</Link>. All rights
                  reserved.
                </p>
              </div>
              <div className="col-md-6">
                <ul className="theme-social-list theme-footer-abs-social">
                  <li>
                    <FontAwesomeIcon icon={faFacebook} href="https://business.facebook.com/thecaminobus" />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faTwitter} href="https://twitter.com/caminobus" />
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faInstagram}
                      href="https://instagram.com/camino.bus"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
