// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "caminobuscom-20190803120711-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1qbjmxgwiz7fa.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:52275aa4-25a1-4ecf-a0c5-fa86731629a3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_qXAtuSN96",
    "aws_user_pools_web_client_id": "4b98gsv7fft146jdc77no811i2",
    "oauth": {}
};


export default awsmobile;
