import React, { Fragment, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link } from 'react-router-dom'

const Logout = ({ setCurrentUser }) => {
  const [error, setError] = useState(null);

  const logout = e => {
    if (e) {
      e.preventDefault();
    }
    Auth.signOut()
      .then(setCurrentUser(null))
      .catch(err => {
        console.log(err);
        setError(true);
      });
  };

  useEffect(logout, []);

  return (
    <Fragment>
      <div className="theme-login-box">
        <div className="theme-login-box-inner">
          {error ? (
            <p>There was an error logging out of your account. <a onClick={logout}>Please try again</a></p>
          ) : (
            <Fragment>
              <p>You've been logged out successfully.</p>
              <p><Link to="/">Click here</Link> to go home.</p>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Logout;
