import React, { Fragment, useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import ReCAPTCHA from "react-google-recaptcha";

const Login = ({ currentUser, setCurrentUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recaptcha, setRecaptcha] = useState(process.env.NODE_ENV !== 'production');
  const [error, setError] = useState(null);

  const onLogin = e => {
    e.preventDefault();
    if (!recaptcha) {
      setError("InvalidRecaptcha");
      return;
    }
    Auth.signIn(email, password)
      .then(user => {
        Auth.userAttributes(user)
          .then(setCurrentUser)
      })
      .catch(({ code }) => setError(code));
  }

  const getLoginAlert = () => {
    switch(error) {
      case 'UserNotConfirmedException':
        return (
          <div className="alert alert-danger" role="alert">
            You have not yet confirmed your account. Please click the link sent to your email.
          </div>
        );
      case 'UserNotFoundException':
        return (
          <div className="alert alert-danger" role="alert">
            We could not find a user with the given email and password. Please try again.
          </div>
        );
      case 'InvalidRecaptcha':
        return (
          <div className="alert alert-danger" role="alert">
            Please prove you are not a robot.
          </div>
        );
      case 'PasswordResetRequiredException':
      case 'NotAuthorizedException':
      default :
        return (
          <div className="alert alert-danger" role="alert">
            Something went wrong. Please try again.
          </div>
        );
    }
  }

  return (
    <Fragment>
      {currentUser ? <Redirect to="/" /> : null}
      <div className="theme-login-header">
        <h1 className="theme-login-title">Sign In</h1>
        <p className="theme-login-subtitle">
          Login into your CaminoBus.com account
        </p>
      </div>
      <div className="theme-login-box">
        <div className="theme-login-box-inner">
          {error && getLoginAlert()}
          <form onSubmit={onLogin} className="theme-login-form">
            <div className="form-group theme-login-form-group">
              <label>E-Mail</label>
              <input
                onChange={({ target }) => setEmail(target.value)}
                className="form-control"
                type="text"
                placeholder="pilgrim@hotmail.com"
                value={email}
              />
            </div>
            <div className="form-group theme-login-form-group">
              <label>Password</label>
              <input
                className="form-control"
                type="password"
                placeholder="********"
                onChange={({ target }) => setPassword(target.value)}
                value={password}
              />
            </div>
            <div className="form-group theme-login-form-group">
              <ReCAPTCHA
                sitekey="6LfRLLEUAAAAAEjBwjG5xdC75J0Y_Pg5s3uuDqXZ"
                onChange={e => setRecaptcha(e)}
              />
              </div>
            <button className="btn btn-uc btn-dark btn-block btn-lg">
              Sign In
            </button>
          </form>
        </div>
        <div className="theme-login-box-alt">
          <p>
            Don't have an account? &nbsp;
            <Link to="/register">Sign up.</Link>
          </p>
        </div>
      </div>
      <p className="theme-login-terms">
        By logging in you accept our <Link to="/terms">terms of use</Link> and <Link to="/privacy">privacy policy</Link>.
      </p>
    </Fragment>
  );
};

export default Login;
