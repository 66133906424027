import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import BusLandscape from '../images/bus-landscape.jpg'

const About = ({ currentUser, currentPage }) => {
  return (
    <Fragment>
      <NavBar currentUser={currentUser} currentPage={currentPage} />
      <div className="theme-hero-area theme-hero-area-half">
        <div className="theme-hero-area-bg-wrap">
          <div
            className="theme-hero-area-bg"
            style={{backgroundImage: `url(${BusLandscape})`, backgroundPosition: 'bottom'}}
          />
          <div className="theme-hero-area-mask theme-hero-area-mask-half" />
          <div className="theme-hero-area-inner-shadow" />
        </div>
        <div className="theme-hero-area-body">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 theme-page-header-abs">
                <div className="theme-page-header theme-page-header-lg">
                  <h1 className="theme-page-header-title">Discover Camino Bus</h1>
                  <p className="theme-page-header-subtitle">
                    The Story of Our Camino
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-page-section theme-page-section-xl theme-page-section-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="theme-about-us">
                <div className="theme-about-us-section sticky-parent">
                  <div className="row row-col-static row-col-reverse">
                    <div className="col-md-4">
                      <div className="sticky-cols">
                        <h4 className="theme-about-us-section-title">Our Camino</h4>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="theme-about-us-section-body">
                        <p>
                          CaminoBus.com is founded and run by pilgrims who
                          love the Camino. We established CaminoBus.com to
                          make it easier & more affordable for pilgrims to
                          get from any international airport to their
                          start of the camino.
                        </p>
                        <p>
                          Our comfortable coaches are fully-equipped with
                          wifi, electrical outlets, and bathrooms. Every single
                          bus we own promises an abundance of leg-room and
                          space for all of your Camino equipment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="theme-about-us-section sticky-parent">
                  <div className="row row-col-static row-col-reverse">
                    <div className="col-md-4">
                      <div className="sticky-cols">
                        <h4 className="theme-about-us-section-title">Cancellations</h4>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="theme-about-us-section-body">
                        <p>
                          We understand that everyone's Camino is
                          unpredictable. If for some reason you are no
                          longer able to make a trip you purchased, we
                          will fully-refund your ticket if you cancel at
                          least 72 hours before departure.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="theme-about-us-section sticky-parent">
                  <div className="row row-col-static row-col-reverse">
                    <div className="col-md-4">
                      <div className="sticky-cols">
                        <h4 className="theme-about-us-section-title">Questions?</h4>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="theme-about-us-section-body">
                        <p>
                          Every person on our team has walked the Camino.
                          If you have any questions about how to get to
                          the Camino, where you should start, or any other
                          Camino-related questions, feel free to reach out!
                        </p>
                      </div>
                      <p style={{marginTop: '50px'}}>
                        <Link className="btn btn-primary btn-block btn-lg" to="/contact">Questions? Contact us!</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default About;
