import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

import {
  faPencilAlt,
  faUnlockAlt,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import NavBar from "../components/NavBar";
import shellhero from "../images/shellhero.jpg";
import SearchResult from "../components/search/SearchResult";
import TimeFilter from "../components/TimeFilter";
import LocationSelector from "../components/LocationSelector";
import Footer from "../components/Footer";
import Loading from "../components/Loading";

const Search = ({
  currentUser,
  departureLocation,
  setDepartureLocation,
  arrivalLocation,
  setArrivalLocation,
  departureDate,
  setDepartureDate,
  numPassengers,
  setNumPassengers,
  locations,
  disableAnalytics
}) => {
  const [localArrivalLocation, setLocalArrivalLocation] = useState(
    arrivalLocation
  );
  const [localDepartureLocation, setLocalDepartureLocation] = useState(
    departureLocation
  );
  const [localDepartureDate, setLocalDepartureDate] = useState(departureDate);
  const [localNumPassengers, setLocalNumPassengers] = useState(numPassengers);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("ascending");
  const [departureTimeFilters, setDepartureTimeFilters] = useState({
    morning: false,
    afternoon: false,
    evening: false
  });
  const [arrivalTimeFilters, setArrivalTimeFilters] = useState({
    morning: false,
    afternoon: false,
    evening: false
  });

  let loadingTimeout;
  const onSearch = () => {
    if (
      localDepartureLocation &&
      localArrivalLocation &&
      localNumPassengers &&
      localDepartureDate.isValid()
    ) {
      setIsLoading(true);
      loadingTimeout = setTimeout(() => setIsLoading(false), 3000);
      setArrivalLocation(localArrivalLocation);
      setDepartureLocation(localDepartureLocation);
      setDepartureDate(localDepartureDate);
      setNumPassengers(localNumPassengers);
      localStorage.setItem("numPassengers", localNumPassengers);
      localStorage.setItem("arrivalLocation", localArrivalLocation.id);
      localStorage.setItem("departureLocation", localDepartureLocation.id);
      localStorage.setItem(
        "departureDate",
        moment(localDepartureDate).format("YYYY-MM-DD")
      );
      if (!disableAnalytics) {
        fetch('https://tkzhwrg60g.execute-api.us-east-1.amazonaws.com/production/stats', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: 'search',
            departureLocation: departureLocation.id,
            arrivalLocation: arrivalLocation.id,
            departureDate: departureDate.unix(),
            numPassengers,
            userId: currentUser ? currentUser.userId : null
          })
        })
      }
    }
  };
  useEffect(onSearch, []);

  const redirectToHome = !departureLocation || !arrivalLocation;
  if (redirectToHome) {
    return <Redirect to="/" />;
  }
  const routes = [];
  if (departureLocation) {
    departureLocation.routes.forEach(route => {
      if (route.destination !== arrivalLocation.id) {
        return;
      }
      let hours = [9, 14, 19];
      const isFromMadrid = (departureLocation.id === 'mad' || departureLocation.id === 'madrid');
      const isMadridToSarria = isFromMadrid && arrivalLocation.id === 'sarria';
      const isToMadrid = (arrivalLocation.id === 'mad' || arrivalLocation.id === 'madrid');
      const isSantiagoToMadrid = isToMadrid && departureLocation === 'santiago';
      if (isMadridToSarria) {
        hours = [9, 11, 14, 19];
      }
      if (isSantiagoToMadrid) {
        hours = [9, 14, 15, 19];
      }
      hours.forEach(departureHour => {
        const departureTime = moment(departureDate).add(departureHour, "hours").add(departureHour, 'minutes');
        const arrivalTime = moment(departureTime).add(
          route.duration,
          "minutes"
        );
        routes.push({
          departureTime,
          arrivalTime,
          departureLocation: departureLocation.id,
          departureLocationLabel: departureLocation.label,
          arrivalLocation: arrivalLocation.id,
          arrivalLocationLabel: arrivalLocation.label,
          price: route.price * numPassengers,
          isSoldOut: departureHour === 11 || departureHour === 15
        });
      });
    });
  }
  const sortRoutes = results => {
    let sortedResults = results;
    if (sortBy === "ascending") {
      sortedResults = results.sort((a, b) =>
        a.departureTime > b.departureTime ? 1 : -1
      );
    } else if (sortBy === "descending") {
      sortedResults = results.sort((a, b) =>
        a.departureTime < b.departureTime ? 1 : -1
      );
    }
    return sortedResults;
  };
  const filterRoutes = () => {
    let filteredRoutes = routes;
    if (Object.values(departureTimeFilters).some(v => v)) {
      filteredRoutes = filteredRoutes.filter(result => {
        const departureHour = parseInt(result.departureTime.format("HH"));
        if (
          departureHour >= 0 &&
          departureHour <= 3 &&
          !departureTimeFilters.evening
        )
          return false;
        if (
          departureHour >= 4 &&
          departureHour <= 11 &&
          !departureTimeFilters.morning
        )
          return false;
        if (
          departureHour >= 12 &&
          departureHour <= 18 &&
          !departureTimeFilters.afternoon
        )
          return false;
        if (
          departureHour >= 18 &&
          departureHour <= 23 &&
          !departureTimeFilters.evening
        )
          return false;

        return true;
      });
    }

    if (Object.values(arrivalTimeFilters).some(v => v)) {
      filteredRoutes = filteredRoutes.filter(result => {
        const arrivalHour = parseInt(result.arrivalTime.format("HH"));
        if (arrivalHour >= 0 && arrivalHour <= 3 && !arrivalTimeFilters.evening)
          return false;
        if (
          arrivalHour >= 4 &&
          arrivalHour <= 11 &&
          !arrivalTimeFilters.morning
        )
          return false;
        if (
          arrivalHour >= 12 &&
          arrivalHour <= 18 &&
          !arrivalTimeFilters.afternoon
        )
          return false;
        if (
          arrivalHour >= 18 &&
          arrivalHour <= 23 &&
          !arrivalTimeFilters.evening
        )
          return false;

        return true;
      });
    }

    return filteredRoutes;
  };
  const formattedRoutes = sortRoutes(filterRoutes());
  return isLoading && arrivalLocation && departureLocation ? (
    <Loading to={arrivalLocation.label} from={departureLocation.label} />
  ) : (
    <Fragment>
      <NavBar />
      <div className="theme-hero-area front">
        <div className="theme-hero-area-bg-wrap">
          <div
            className="theme-hero-area-bg theme-hero-area-bg-blur"
            style={{ backgroundImage: `url(${shellhero})` }}
          />
          <div className="theme-hero-area-mask theme-hero-area-mask-half" />
        </div>
        <div className="theme-hero-area-body">
          <div className="container">
            <div className="_pb-50 _pt-100 _pv-mob-50">
              <div className="theme-search-area _mob-h theme-search-area-white">
                <div className="theme-search-area-header _mb-20">
                  <h1 className="theme-search-area-title theme-search-area-title-sm">
                    Direct routes from <b>{departureLocation.label}</b> to{" "}
                    <b>{arrivalLocation.label}</b>
                  </h1>
                </div>
                <div className="theme-search-area-form" id="hero-search-form">
                  <div className="row" data-gutter="10">
                    <div className="col-md-6 ">
                      <div className="row" data-gutter="10">
                        <div className="col-md-6 ">
                          <div className="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-sm theme-search-area-section-fade-white theme-search-area-section-no-border">
                            <label className="theme-search-area-section-label _op-06">
                              From
                            </label>
                            <div className="theme-search-area-section-inner">
                              <i className="theme-search-area-section-icon lin lin-location-pin" />
                              <LocationSelector
                                placeholder="Departure"
                                selectedLocation={localDepartureLocation}
                                setSelectedLocation={setLocalDepartureLocation}
                                locations={locations}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="theme-search-area-section theme-search-area-section-curved theme-search-area-section-sm theme-search-area-section-fade-white theme-search-area-section-no-border">
                            <label className="theme-search-area-section-label _op-06">
                              To
                            </label>
                            <div className="theme-search-area-section-inner">
                              <i className="theme-search-area-section-icon lin lin-location-pin" />
                              <LocationSelector
                                placeholder="Destination"
                                selectedLocation={localArrivalLocation}
                                setSelectedLocation={setLocalArrivalLocation}
                                locations={locations}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <div className="row" data-gutter="10">
                        <div className="col-md-9 ">
                          <div className="theme-search-area-section theme-search-area-section-curved theme-search-area-section-sm theme-search-area-section-fade-white theme-search-area-section-no-border">
                            <label className="theme-search-area-section-label _op-06">
                              Depart
                            </label>
                            <div className="theme-search-area-section-inner">
                              <i className="theme-search-area-section-icon lin lin-calendar" />
                              <input
                                onChange={({ target }) =>
                                  setLocalDepartureDate(moment(target.value))
                                }
                                value={localDepartureDate.format("YYYY-MM-DD")}
                                className="theme-search-area-section-input datePickerStart _mob-h"
                                type="date"
                                min={moment().format("YYYY-MM-DD")}
                              />
                              <input
                                onChange={({ target }) =>
                                  setLocalDepartureDate(moment(target.value))
                                }
                                value={localDepartureDate.format("YYYY-MM-DD")}
                                className="theme-search-area-section-input _desk-h mobile-picker"
                                type="date"
                                min={moment().format("YYYY-MM-DD")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div
                            className="theme-search-area-section theme-search-area-section-curved theme-search-area-section-sm theme-search-area-section-fade-white theme-search-area-section-no-border quantity-selector"
                            data-increment="Passengers"
                          >
                            <label className="theme-search-area-section-label _op-06">
                              Passengers
                            </label>
                            <div className="theme-search-area-section-inner">
                              <i className="theme-search-area-section-icon lin lin-people" />
                              <input
                                className="theme-search-area-section-input"
                                value={localNumPassengers}
                                onChange={({ target }) =>
                                  setLocalNumPassengers(target.value)
                                }
                                type="number"
                                required
                                minLength={1}
                                min="1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div onClick={onSearch} className="col-md-2 ">
                      <button className="theme-search-area-submit _tt-uc theme-search-area-submit-curved theme-search-area-submit-sm theme-search-area-submit-no-border theme-search-area-submit-primary">
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="theme-search-area-inline _desk-h theme-search-area-inline-white">
                <h4 className="theme-search-area-inline-title">
                  Direct routes from {departureLocation.label} to{" "}
                  {arrivalLocation.label}
                </h4>
                <div className="theme-search-area-inline-details">
                  <p>{departureDate.format("MMMM DD, YYYY")}</p>
                  <p>
                    {numPassengers}{" "}
                    {numPassengers === 1 ? "passenger" : "passengers"}
                  </p>
                </div>
                <Link
                  className="theme-search-area-inline-link magnific-inline"
                  to="/"
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                  &nbsp;Edit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-page-section theme-page-section-gray">
        <div className="container">
          <div
            className="row row-col-static"
            id="sticky-parent"
            data-gutter="20"
          >
            <div className="col-md-3">
              <div className="sticky-col _mob-h">
                <div className="theme-search-results-sidebar">
                  <div className="theme-search-results-sidebar-sections _mb-20 _br-2">
                    <TimeFilter
                      timeFilters={departureTimeFilters}
                      setTimeFilters={setDepartureTimeFilters}
                      title={
                        departureLocation
                          ? `Depart from ${departureLocation.label}`
                          : "Departure"
                      }
                    />
                    <TimeFilter
                      timeFilters={arrivalTimeFilters}
                      setTimeFilters={setArrivalTimeFilters}
                      title={
                        arrivalLocation
                          ? `Arrive at ${arrivalLocation.label}`
                          : "Arrival"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="theme-search-results">
                {formattedRoutes.length ? (
                  <Fragment>
                    <div className="theme-search-results-sort _mob-h clearfix">
                      <h5 className="theme-search-results-sort-title">
                        Sort by:
                      </h5>
                      <ul className="theme-search-results-sort-list">
                        <li
                          onClick={() => setSortBy("ascending")}
                          className={sortBy === "ascending" ? "active" : null}
                        >
                          Earliest to latest
                        </li>
                        <li
                          onClick={() => setSortBy("descending")}
                          className={sortBy === "descending" ? "active" : null}
                        >
                          Latest to earliest
                        </li>
                      </ul>
                    </div>
                    <div className="theme-search-results-sort-select _desk-h">
                      <select
                        onChange={({ target }) => setSortBy(target.value)}
                      >
                        <option value="ascending">Earliest to latest</option>
                        <option value="descending">Latest to earliest</option>
                      </select>
                      <FontAwesomeIcon icon={faAngleDown} aria-hidden="true" />
                    </div>
                    {!currentUser && (
                      <div className="theme-search-results-sign-in _mob-h bg-grad">
                        <div className="theme-search-results-sign-in-icon">
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </div>
                        <h5 className="theme-search-results-sign-in-title">
                          Sign in to unlock our secret deals. Save up to{" "}
                          <b>50%</b>
                        </h5>
                        <Link
                          className="btn theme-search-results-sign-in-btn btn-ghost btn-white"
                          to="/login"
                        >
                          Sign in now
                        </Link>
                      </div>
                    )}
                    {formattedRoutes.map(route => (
                      <SearchResult
                        key={route.departureTime}
                        {...route}
                        numPassengers={numPassengers}
                        currentUser={currentUser}
                        disableAnalytics={disableAnalytics}
                      />
                    ))}
                  </Fragment>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <h3 style={{ margin: "15px" }}>
                      Oh no! It looks like we have no buses on this camino. Please try a new search.
                    </h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Search;
