import React, { Fragment } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Trips = ({ currentUser, currentPage }) => {
  return currentUser ? (
    <Fragment>
      <NavBar currentUser={currentUser} currentPage={currentPage} opaque />
      <div style={{ minHeight: 'calc(100vh - 40px)' }} className="theme-page-section-account theme-page-section theme-page-section-gray theme-page-section-lg">
        <div className="container">
          <div className="row">
            <div className="col-md-2-5 ">
              <div className="theme-account-sidebar">
                <nav className="theme-account-nav">
                  <ul className="theme-account-nav-list">
                    <li>
                      <Link to="/profile">
                        <i className="fa fa-cog" />
                        Preferences
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/trips">
                        <i className="fa fa-history" />
                        Trips
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-md-9-5 ">
              <h1 className="theme-account-page-title">Your Trips</h1>
              <div className="row">
                <div className="col-md-9 ">
                  <div className="theme-account-preferences">
                    You have not purchased any trips so far.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  ) : (
    <Redirect to="/login" />
  );
};

export default Trips;
