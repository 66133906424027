import React, { Fragment } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import busLandscape from "../images/bus-landscape.jpg";

const TermsOfUse = ({ currentUser, currentPage }) => {
  return (
    <Fragment>
      <NavBar currentUser={currentUser} currentPage={currentPage} />
      <div className="theme-hero-area _h-50vh theme-hero-area-sm">
        <div className="theme-hero-area-bg-wrap">
          <div
            className="theme-hero-area-bg"
            style={{ backgroundImage: `url(${busLandscape})` }}
          />
          <div className="theme-hero-area-mask theme-hero-area-mask-half" />
          <div className="theme-hero-area-inner-shadow" />
        </div>
        <div className="theme-hero-area-body">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 theme-page-header-abs">
                <div className="theme-page-header theme-page-header-lg">
                  <h1 className="theme-page-header-title">Terms of use</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-page-section theme-page-section-xl theme-page-section-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="theme-contact">
                <div className="row row-col-mob-gap">
                  <div className="col-md-7">
                    <h2>Web Site Terms and Conditions of Use</h2>

                    <h3>1. Terms</h3>

                    <p>
                      By accessing this web site, you are agreeing to be bound
                      by these web site Terms and Conditions of Use, all
                      applicable laws and regulations, and agree that you are
                      responsible for compliance with any applicable local laws.
                      If you do not agree with any of these terms, you are
                      prohibited from using or accessing this site. The
                      materials contained in this web site are protected by
                      applicable copyright and trade mark law.
                    </p>

                    <h3>2. Use License</h3>

                    <ol type="a">
                      <li>
                        Permission is granted to temporarily download one copy
                        of the materials (information or software) on
                        CaminoBus.com's web site for personal, non-commercial
                        transitory viewing only. This is the grant of a license,
                        not a transfer of title, and under this license you may
                        not:
                        <ol type="i">
                          <li>modify or copy the materials;</li>
                          <li>
                            use the materials for any commercial purpose, or for
                            any public display (commercial or non-commercial);
                          </li>
                          <li>
                            attempt to decompile or reverse engineer any
                            software contained on CaminoBus.com's web site;
                          </li>
                          <li>
                            remove any copyright or other proprietary notations
                            from the materials; or
                          </li>
                          <li>
                            transfer the materials to another person or "mirror"
                            the materials on any other server.
                          </li>
                        </ol>
                      </li>
                      <li>
                        This license shall automatically terminate if you
                        violate any of these restrictions and may be terminated
                        by CaminoBus.com at any time. Upon terminating your
                        viewing of these materials or upon the termination of
                        this license, you must destroy any downloaded materials
                        in your possession whether in electronic or printed
                        format.
                      </li>
                    </ol>

                    <h3>3. Disclaimer</h3>

                    <ol type="a">
                      <li>
                        The materials on CaminoBus.com's web site are provided
                        "as is". CaminoBus.com makes no warranties, expressed or
                        implied, and hereby disclaims and negates all other
                        warranties, including without limitation, implied
                        warranties or conditions of merchantability, fitness for
                        a particular purpose, or non-infringement of
                        intellectual property or other violation of rights.
                        Further, CaminoBus.com does not warrant or make any
                        representations concerning the accuracy, likely results,
                        or reliability of the use of the materials on its
                        Internet web site or otherwise relating to such
                        materials or on any sites linked to this site.
                      </li>
                    </ol>

                    <h3>4. Limitations</h3>

                    <p>
                      In no event shall CaminoBus.com or its suppliers be liable
                      for any damages (including, without limitation, damages
                      for loss of data or profit, or due to business
                      interruption,) arising out of the use or inability to use
                      the materials on CaminoBus.com's Internet site, even if
                      CaminoBus.com or a CaminoBus.com authorized representative
                      has been notified orally or in writing of the possibility
                      of such damage. Because some jurisdictions do not allow
                      limitations on implied warranties, or limitations of
                      liability for consequential or incidental damages, these
                      limitations may not apply to you.
                    </p>

                    <h3>5. Revisions and Errata</h3>

                    <p>
                      The materials appearing on CaminoBus.com's web site could
                      include technical, typographical, or photographic errors.
                      CaminoBus.com does not warrant that any of the materials
                      on its web site are accurate, complete, or current.
                      CaminoBus.com may make changes to the materials contained
                      on its web site at any time without notice. CaminoBus.com
                      does not, however, make any commitment to update the
                      materials.
                    </p>

                    <h3>6. Links</h3>

                    <p>
                      CaminoBus.com has not reviewed all of the sites linked to
                      its Internet web site and is not responsible for the
                      contents of any such linked site. The inclusion of any
                      link does not imply endorsement by CaminoBus.com of the
                      site. Use of any such linked web site is at the user's own
                      risk.
                    </p>

                    <h3>7. Site Terms of Use Modifications</h3>

                    <p>
                      CaminoBus.com may revise these terms of use for its web
                      site at any time without notice. By using this web site
                      you are agreeing to be bound by the then current version
                      of these Terms and Conditions of Use.
                    </p>

                    <h3>8. Governing Law</h3>

                    <p>
                      Any claim relating to CaminoBus.com's web site shall be
                      governed by the laws of the State of California without
                      regard to its conflict of law provisions.
                    </p>

                    <p>
                      General Terms and Conditions applicable to Use of a Web
                      Site.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default TermsOfUse;
