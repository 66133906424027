import React, { Fragment, useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import caminoShell from "../images/camino-shell.jpg";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

const ThankYou = ({ currentPage, currentUser, disableAnalytics }) => {
  const [redirectHome, setRedirectHome] = useState(false);
  const [count, setCount] = useState(5);

  useEffect(() => {
    const search = window.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const confirmedUser = params.get('username');
    if (!disableAnalytics) {
      ReactGA.event({
        category: 'login',
        action: 'confirmed',
        label: confirmedUser
      });
    }
  }, [])

  useEffect(() => {
    if (count <= 0) {
      setRedirectHome(true);
    } else {
      setTimeout(() => setCount(count-1), 1000);
    }
  }, [count])

  if (redirectHome) {
    return <Redirect to='/' />
  }
  return (
    <Fragment>
      <NavBar currentPage={currentPage} currentUser={currentUser} />
      <div className="theme-hero-area">
        <div className="theme-hero-area-bg-wrap">
          <div className="theme-hero-area-bg" style={{backgroundImage: `url(${caminoShell})`}} />
          <div className="theme-hero-area-mask theme-hero-area-mask-strong"></div>
        </div>
        <div className="theme-hero-area-body poor-mans-fill-height">
          <div className="theme-page-section _pt-100 theme-page-section-xl">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-md-offset-4">
                  <div className="theme-login theme-login-white">
                    <div className="theme-login-box">
                      <div className="theme-login-box-inner">
                        <p>Thank you! Your account has been confirmed. Buen camino.</p>
                        <p>{`Redirect to homepage in ${count}...`}</p>
                        <Link className="btn btn-primary btn-block btn-lg" to="/">Go home</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default ThankYou;