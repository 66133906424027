import React, { Fragment, useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import busLandscape from "../images/bus-landscape.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

const Contact = ({ currentUser, currentPage }) => {
  const [name, setName] = useState(currentUser ? currentUser.firstName : "");
  const [email, setEmail] = useState(currentUser ? currentUser.email : "");
  const [messageBody, setMessageBody] = useState("");
  const [recaptcha, setRecaptcha] = useState(
    process.env.NODE_ENV !== "production"
  );
  const [error, setError] = useState(null);
  const [isSent, setIsSent] = useState(false);

  const validateEmail = () =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

  const onSubmit = e => {
    e.preventDefault();
    setError(null);
    if (!name.length) {
      setError("Please tell us your name.");
      return;
    } else if (!validateEmail()) {
      setError("Please provide a valid email address.");
      return;
    } else if (!recaptcha) {
      setError("Please prove you are not a robot.");
      return;
    }
    makePostRequest();
  };

  const makePostRequest = () => {
    fetch(
      "https://c9z2vxx2y8.execute-api.us-east-1.amazonaws.com/production/email/send/",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name,
          email,
          messageBody,
          userId: currentUser ? currentUser.userId : 'Not a member'
        })
      }
    )
      .then(() => setIsSent(true))
      .catch(() => setError("There was an issue. Please try again."));
  };

  return (
    <Fragment>
      <NavBar currentUser={currentUser} currentPage={currentPage} />
      <div className="theme-hero-area _h-50vh theme-hero-area-sm">
        <div className="theme-hero-area-bg-wrap">
          <div
            className="theme-hero-area-bg"
            style={{ backgroundImage: `url(${busLandscape})`, backgroundPosition: 'bottom' }}
          />
          <div className="theme-hero-area-mask theme-hero-area-mask-half" />
          <div className="theme-hero-area-inner-shadow" />
        </div>
        <div className="theme-hero-area-body">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 theme-page-header-abs">
                <div className="theme-page-header theme-page-header-lg">
                  <h1 className="theme-page-header-title">Contact us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-page-section theme-page-section-xl theme-page-section-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="theme-contact">
                <h2 className="theme-contact-title">
                  {isSent ? "Success!" : "Send us an email"}
                </h2>
                <div className="row row-col-mob-gap">
                  <div className="col-md-7">
                    {isSent ? (
                      <Fragment>
                        <p style={{ marginBottom: "20px" }}>
                          Thank you for the email. We will get back to you within 24 hours.
                        </p>
                        <Link to="/">Go back home.</Link>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <p style={{ marginBottom: "20px" }}>
                          Questions? Send us an email and we will get back to
                          you within 24 hours.
                        </p>
                        <form onSubmit={onSubmit}>
                          {error && (
                            <div className="alert alert-danger" role="alert">
                              {error}
                            </div>
                          )}
                          <div className="form-group theme-contact-form-group">
                            <label>Name</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Pilgrim Smith"
                              value={name}
                              onChange={e => setName(e.target.value)}
                            />
                          </div>
                          <div className="form-group theme-contact-form-group">
                            <label>Email</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="peregrino@gmail.com"
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-group theme-contact-form-group">
                            <label>Message</label>
                            <textarea
                              className="form-control"
                              rows="5"
                              placeholder="I would like to say that..."
                              onChange={e => setMessageBody(e.target.value)}
                              value={messageBody}
                            />
                          </div>
                          <ReCAPTCHA
                            sitekey="6LfRLLEUAAAAAEjBwjG5xdC75J0Y_Pg5s3uuDqXZ"
                            onChange={e => setRecaptcha(e)}
                          />
                          <button
                            className="btn btn-uc btn-primary btn-lg"
                            style={{ marginTop: "20px" }}
                          >
                            Send Your Message
                          </button>
                        </form>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Contact;
