import React, { Fragment, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';

const Register = ({ currentUser, setCurrentUser, disableAnalytics }) => {
  const [isAwaitingVerification, setIsAwaitingVerification] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const onRegister = e => {
    e.preventDefault();
    let errorCode = '';
    if (!firstName.length) {
      errorCode = "InvalidFirstName"
    } else if (!lastName.length) {
      errorCode = "InvalidLastName"
    } else if (!email.length) {
      errorCode = "InvalidEmailName"
    } else if (!password || password.length < 8) {
      errorCode = "InvalidPassword"
    }
    if (errorCode) {
      setError(errorCode);
      return;
    }
    if (!disableAnalytics) {
      ReactGA.event({
        category: 'registration',
        action: 'initiated',
        label: email
      });
    }
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email: email,
        name: firstName,
        family_name: lastName
      }
    })
      .then(() => setIsAwaitingVerification(true))
      .catch((err) => setError(err.code || err));
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then(user => {
      Auth.userAttributes(user)
        .then(setCurrentUser)
    })
    .catch(() => {});
  }, [])

  const getRegistrationAlert = () => {
    switch(error) {
      case 'UsernameExistsException':
        return (
          <div className="alert alert-danger" role="alert">
            An account with that email already exists.
          </div>
        );
      case 'InvalidFirstName':
        return (
          <div className="alert alert-danger" role="alert">
            First name cannot be empty.
          </div>
        );
      case 'InvalidLastName':
        return (
          <div className="alert alert-danger" role="alert">
            Last name cannot be empty.
          </div>
        );
      case 'InvalidEmail':
        return (
          <div className="alert alert-danger" role="alert">
            Email cannot be empty.
          </div>
        );
      case 'InvalidPassword':
        return (
          <div className="alert alert-danger" role="alert">
            Password must be at least 8 characters.
          </div>
        );
      default :
        return (
          <div className="alert alert-danger" role="alert">
            Something went wrong. Please try again.
          </div>
        );
    }
  }

  return (
    <Fragment>
      {currentUser ? <Redirect to="/" /> : null}
      {!isAwaitingVerification && (
        <div className="theme-login-header">
          <h1 className="theme-login-title">Sign Up</h1>
          <p className="theme-login-subtitle">Create new Camino Bus account</p>
        </div>
      )}
      <div className="theme-login-box">
        <div className="theme-login-box-inner">
          {isAwaitingVerification ? (
            <div>Thank you! A confirmation email has been sent to {email}. Please click the link to verify your account.</div>
          ) : (
            <Fragment>
              {error && getRegistrationAlert()}
              <form onSubmit={onRegister} className="theme-login-form">
                <div className="form-group theme-login-form-group">
                  <label>First name</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Peregrino"
                    value={firstName}
                    onChange={({ target }) => setFirstName(target.value)}
                  />
                </div>
                <div className="form-group theme-login-form-group">
                  <label>Last name</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Smith"
                    value={lastName}
                    onChange={({ target }) => setLastName(target.value)}
                  />
                </div>
                <div className="form-group theme-login-form-group">
                  <label>E-Mail address</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="pilgrim@gmail.com"
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                  />
                </div>
                <div className="form-group theme-login-form-group">
                  <label>Password</label>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="********"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                </div>
                <button className="btn btn-uc btn-dark btn-block btn-lg">
                  Create Account
                </button>
              </form>
            </Fragment>
          )}
        </div>
      {!isAwaitingVerification && (
        <div className="theme-login-box-alt">
          <p>
            Already have an account? &nbsp;
            <Link to="/login">Sign in.</Link>
          </p>
        </div>
      )}
      </div>
      {!isAwaitingVerification && (
        <p className="theme-login-terms">
          By logging in you accept our <Link to="/terms">terms of use</Link> and <Link to="/privacy">privacy policy</Link>.
        </p>
      )}
    </Fragment>
  );
};

export default Register;
