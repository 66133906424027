import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faAngleDown } from "@fortawesome/free-solid-svg-icons";

const AccountSelector = ({ currentPage, currentUser }) => {
  return (
    <li className="navbar-nav-item-user dropdown">
      { currentUser ? (
        <Fragment>
          <a
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FontAwesomeIcon
              icon={faUserCircle}
              className="navbar-nav-item-user-icon"
            />
            Welcome, {currentUser.firstName}
            <FontAwesomeIcon
              icon={faAngleDown}
              style={{ marginLeft: '10px' }}
            />
          </a>
          <ul className="dropdown-menu">
            <li>
              <Link to="/profile">My Profile</Link>
            </li>
            <li>
              <Link to="/trips">My Trips</Link>
            </li>
            <li>
              <Link to="/logout">Logout</Link>
            </li>
          </ul>
        </Fragment>
      ) : (
        <Fragment>
          <ul className="nav navbar-nav">
            <li className={currentPage === 'login' ? 'active' : ''}>
              <Link to="/login">Login</Link>
            </li>
            <li className={currentPage === 'register' ? 'active' : ''}>
              <Link to="/register">Sign up</Link>
            </li>
          </ul>
        </Fragment>
      )}
    </li>
  );
}

export default AccountSelector;