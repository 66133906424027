import React, { useState } from "react";
import { Link } from "react-router-dom";
import AccountSelector from "./AccountSelector";
import logo from "../images/caminoarrow.png";

const NavBar = ({ currentPage, currentUser, opaque }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <nav
      className={`navbar navbar-default navbar-inverse navbar-theme navbar-theme-abs navbar-theme-border ${
        opaque ? "" : "navbar-theme-transparent"
      }`}
      id="main-nav"
    >
      <div className="container">
        <div className="navbar-inner nav">
          <div className="navbar-header">
            <button
              className="navbar-toggle collapsed"
              data-target="#navbar-main"
              data-toggle="collapse"
              type="button"
              area-expanded="false"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div
            className={`${isExpanded ? "" : "collapse "}navbar-collapse`}
            id="navbar-main"
          >
            <ul className="nav navbar-nav">
              <li className={currentPage === "home" ? "active" : ""}>
                <Link to="/">Home</Link>
              </li>
              <li className={currentPage === "about" ? "active" : ""}>
                <Link to="/about">About</Link>
              </li>
              <li className={currentPage === "contact" ? "active" : ""}>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <ul className="nav navbar-nav navbar-right">
              {/* <CurrencySelector />
              <LanguageSelector /> */}
              <AccountSelector
                currentPage={currentPage}
                currentUser={currentUser}
              />
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
