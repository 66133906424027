import React, { Fragment } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import caminoShell from "../images/camino-shell.jpg";

const Authentication = ({ AuthScreen, currentPage, currentUser, setCurrentUser }) => {
  return (
    <Fragment>
      <NavBar currentPage={currentPage} currentUser={currentUser} />
      <div className="theme-hero-area">
        <div className="theme-hero-area-bg-wrap">
          <div className="theme-hero-area-bg" style={{backgroundImage: `url(${caminoShell})`}} />
          <div className="theme-hero-area-mask theme-hero-area-mask-strong"></div>
        </div>
        <div className="theme-hero-area-body poor-mans-fill-height">
          <div className="theme-page-section _pt-100 theme-page-section-xl">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-md-offset-4">
                  <div className="theme-login theme-login-white">
                    <AuthScreen setCurrentUser={setCurrentUser} currentUser={currentUser} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Authentication;