import React, { useState, Fragment } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Profile = ({ currentUser, currentPage }) => {
  const [isEditingFirstName, setIsEditingFirstName] = useState(false);
  const [isEditingLastName, setIsEditingLastName] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const onChangePassword = e => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);
    if (newPassword !== newPasswordConfirmation) {
      setErrorMessage('Passwords do not match!');
      return;
    } else if (newPassword.length < 8) {
      setErrorMessage('Passwords must be at least 8 characters.');
      return;
    }
    Auth.currentAuthenticatedUser()
      .then(user => Auth.changePassword(user, currentPassword, newPassword))
      .then(() => {
        setIsEditingPassword(false);
        setCurrentPassword('');
        setNewPassword('');
        setNewPasswordConfirmation('');
        setSuccessMessage('Password successfully change.');
      })
      .catch(() => setErrorMessage('Something went wrong changing the password. Please try again!'));
  }

  const onChangeFirstName = e => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);
    if (!firstName.length) {
      setErrorMessage('Please enter a first name.');
      return;
    }
    Auth.currentAuthenticatedUser()
      .then(user => Auth.updateUserAttributes(user, { name: firstName }))
      .then(() => {
        currentUser.firstName = firstName;
        setIsEditingFirstName(false);
        setFirstName('');
        setSuccessMessage('Name successfully change.');
      })
      .catch(err => { console.log(err); setErrorMessage('Something went wrong. Please try again!') });
  }

  const onChangeLastName = e => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);
    if (!lastName.length) {
      setErrorMessage('Please enter a last name.');
      return;
    }
    Auth.currentAuthenticatedUser()
      .then(user => Auth.updateUserAttributes(user, { family_name: lastName }))
      .then(user => {
        currentUser.lastName = lastName;
        setIsEditingLastName(false);
        setLastName('');
        setSuccessMessage('Last name successfully change.');
      })
      .catch(() => setErrorMessage('Something went wrong. Please try again!'));
  }

  return currentUser ? (
    <Fragment>
      <NavBar currentUser={currentUser} currentPage={currentPage} opaque />
      <div style={{ minHeight: 'calc(100vh - 40px)' }} className="theme-page-section-account theme-page-section theme-page-section-gray theme-page-section-lg">
        <div className="container">
          <div className="row">
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="col-md-2-5 ">
              <div className="theme-account-sidebar">
                <nav className="theme-account-nav">
                  <ul className="theme-account-nav-list">
                    <li className="active">
                      <Link to="/profile">
                        <i className="fa fa-cog" />
                        Preferences
                      </Link>
                    </li>
                    <li>
                      <Link to="/trips">
                        <i className="fa fa-history" />
                        Trips
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-md-9-5 ">
              <h1 className="theme-account-page-title">Preferences</h1>
              <div className="row">
                <div className="col-md-9 ">
                  <div className="theme-account-preferences">
                    <div className="theme-account-preferences-item">
                      <div className="row">
                        <div className="col-md-3 ">
                          <h5 className="theme-account-preferences-item-title">
                            Email Address
                          </h5>
                        </div>
                        <div className="col-md-7 ">
                          <p className="theme-account-preferences-item-value">
                            {currentUser.email}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="theme-account-preferences-item">
                      <div className="row">
                        <div className="col-md-3 ">
                          <h5 className="theme-account-preferences-item-title">
                            First Name
                          </h5>
                        </div>
                        <div className="col-md-7 ">
                          <p className="theme-account-preferences-item-value">
                            {currentUser.firstName}
                          </p>
                          <div className={`${isEditingFirstName ? '' : 'collapse'}`} id="ChangePassword">
                            <div className="theme-account-preferences-item-change">
                              <form onSubmit={onChangeFirstName}>
                                <div className="form-group theme-account-preferences-item-change-form">
                                  <label>New First Name</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                  />
                                </div>
                                <div className="theme-account-preferences-item-change-actions">
                                  <button className="btn btn-sm btn-primary" href="#">
                                    Save changes
                                  </button>
                                  <a
                                    className="btn btn-sm btn-default"
                                    onClick={() => setIsEditingFirstName(false)}
                                  >
                                    Cancel
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            className="theme-account-preferences-item-change-link"
                            onClick={() => setIsEditingFirstName(!isEditingFirstName)}
                          >
                            <i className="fa fa-pencil" />
                            edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="theme-account-preferences-item">
                      <div className="row">
                        <div className="col-md-3 ">
                          <h5 className="theme-account-preferences-item-title">
                            Last Name
                          </h5>
                        </div>
                        <div className="col-md-7 ">
                          <p className="theme-account-preferences-item-value">
                            {currentUser.lastName}
                          </p>
                          <div className={`${isEditingLastName ? '' : 'collapse'}`} id="ChangePassword">
                            <div className="theme-account-preferences-item-change">
                              <form onSubmit={onChangeLastName}>
                                <div className="form-group theme-account-preferences-item-change-form">
                                  <label>New Last Name</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                  />
                                </div>
                                <div className="theme-account-preferences-item-change-actions">
                                  <button className="btn btn-sm btn-primary" href="#">
                                    Save changes
                                  </button>
                                  <a
                                    className="btn btn-sm btn-default"
                                    onClick={() => setIsEditingLastName(false)}
                                  >
                                    Cancel
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            className="theme-account-preferences-item-change-link"
                            onClick={() => setIsEditingLastName(!isEditingLastName)}
                          >
                            <i className="fa fa-pencil" />
                            edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="theme-account-preferences-item">
                      <div className="row">
                        <div className="col-md-3 ">
                          <h5 className="theme-account-preferences-item-title">
                            Password
                          </h5>
                        </div>
                        <div className="col-md-7 ">
                          <p className="theme-account-preferences-item-value">
                            ********
                          </p>
                          <div className={`${isEditingPassword ? '' : 'collapse'}`} id="ChangePassword">
                            <div className="theme-account-preferences-item-change">
                              <p className="theme-account-preferences-item-change-description">
                                It's a good idea to use a strong password
                                <br />
                                that you're not using elsewhere
                              </p>
                              <form onSubmit={onChangePassword}>
                                <div className="form-group theme-account-preferences-item-change-form">
                                  <label>Current Password</label>
                                  <input
                                    className="form-control"
                                    type="password"
                                    value={currentPassword}
                                    onChange={e => setCurrentPassword(e.target.value)}
                                  />
                                  <label>New Password</label>
                                  <input
                                    className="form-control"
                                    type="password"
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                  />
                                  <label>Re-type New Password</label>
                                  <input
                                    className="form-control"
                                    type="password"
                                    value={newPasswordConfirmation}
                                    onChange={e => setNewPasswordConfirmation(e.target.value)}
                                  />
                                </div>
                                <div className="theme-account-preferences-item-change-actions">
                                  <button className="btn btn-sm btn-primary" href="#">
                                    Save changes
                                  </button>
                                  <a
                                    className="btn btn-sm btn-default"
                                    onClick={() => setIsEditingPassword(false)}
                                  >
                                    Cancel
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            className="theme-account-preferences-item-change-link"
                            onClick={() => setIsEditingPassword(!isEditingPassword)}
                          >
                            <i className="fa fa-pencil" />
                            edit
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  ) : (
    <Redirect to="/login" />
  );
};

export default Profile;
