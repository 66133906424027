import React, { Fragment } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import busLandscape from "../images/bus-landscape.jpg";

const PrivacyPolicy = ({ currentUser, currentPage }) => {
  return (
    <Fragment>
      <NavBar currentUser={currentUser} currentPage={currentPage} />
      <div className="theme-hero-area _h-50vh theme-hero-area-sm">
        <div className="theme-hero-area-bg-wrap">
          <div
            className="theme-hero-area-bg"
            style={{ backgroundImage: `url(${busLandscape})` }}
          />
          <div className="theme-hero-area-mask theme-hero-area-mask-half" />
          <div className="theme-hero-area-inner-shadow" />
        </div>
        <div className="theme-hero-area-body">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 theme-page-header-abs">
                <div className="theme-page-header theme-page-header-lg">
                  <h1 className="theme-page-header-title">Privacy policy</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-page-section theme-page-section-xl theme-page-section-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="theme-contact">
                <div className="row row-col-mob-gap">
                  <div className="col-md-7">
                    <h2>Privacy Policy</h2>

                    <p>
                      Your privacy is very important to us. Accordingly, we have
                      developed this Policy in order for you to understand how
                      we collect, use, communicate and disclose and make use of
                      personal information. The following outlines our privacy
                      policy.
                    </p>

                    <ul>
                      <li>
                        Before or at the time of collecting personal
                        information, we will identify the purposes for which
                        information is being collected.
                      </li>
                      <li>
                        We will collect and use of personal information solely
                        with the objective of fulfilling those purposes
                        specified by us and for other compatible purposes,
                        unless we obtain the consent of the individual concerned
                        or as required by law.
                      </li>
                      <li>
                        We will only retain personal information as long as
                        necessary for the fulfillment of those purposes.
                      </li>
                      <li>
                        We will collect personal information by lawful and fair
                        means and, where appropriate, with the knowledge or
                        consent of the individual concerned.
                      </li>
                      <li>
                        Personal data should be relevant to the purposes for
                        which it is to be used, and, to the extent necessary for
                        those purposes, should be accurate, complete, and
                        up-to-date.
                      </li>
                      <li>
                        We will protect personal information by reasonable
                        security safeguards against loss or theft, as well as
                        unauthorized access, disclosure, copying, use or
                        modification.
                      </li>
                      <li>
                        We will make readily available to customers information
                        about our policies and practices relating to the
                        management of personal information.
                      </li>
                    </ul>

                    <p>
                      We are committed to conducting our business in accordance
                      with these principles in order to ensure that the
                      confidentiality of personal information is protected and
                      maintained.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default PrivacyPolicy;
