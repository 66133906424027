import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import backgroundImage from "../images/backpacking.jpg";
import logo from "../images/caminoarrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";
import ReactGA from "react-ga";

const Book = ({ location, disableAnalytics }) => {
  const locationState = location.state || {};
  const [isSubscribed, setIsSubscribed] = useState(false);
  const {
    pricePerPassenger,
    numPassengers,
    departureTime,
    departureLocation,
    arrivalTime,
    arrivalLocation,
    currentUser
  } = locationState;

  useEffect(() => {
    if (!disableAnalytics) {
      if (currentUser) {
        ReactGA.event({
          category: "purchase",
          action: "success",
          label: currentUser.userId,
          value: totalPrice
        });
        fetch('https://tkzhwrg60g.execute-api.us-east-1.amazonaws.com/production/stats', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: 'purchase',
            departureLocation: departureLocation,
            arrivalLocation: arrivalLocation,
            departureTime,
            arrivalTime,
            numPassengers,
            pricePerPassenger,
            userId: currentUser ? currentUser.userId : 'guest'
          })
        })
      }
    }
  }, []);

  const totalPrice = pricePerPassenger * numPassengers;
  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const onSubscribe = () => {
    setIsSubscribed(true);
    if (!disableAnalytics) {
      ReactGA.event({
        category: "subscribe",
        action: "success",
        label: currentUser.userId
      });
      fetch('https://tkzhwrg60g.execute-api.us-east-1.amazonaws.com/production/stats', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'subscription',
          departureLocation: departureLocation,
          arrivalLocation: arrivalLocation,
          departureTime,
          arrivalTime,
          numPassengers,
          pricePerPassenger,
          userEmail: currentUser.email,
          userId: currentUser ? currentUser.userId : 'guest'
        })
      })
    }
  };

  return (
    <div className="theme-hero-area _h-desk-100vh poor-mans-fill-height">
      <div className="theme-hero-area-bg-wrap">
        <div
          className="theme-hero-area-bg"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />
        <div className="theme-hero-area-mask theme-hero-area-mask-strong" />
        <div className="theme-hero-area-inner-shadow" />
      </div>
      <div className="theme-hero-area-body _pos-desk-v-c _w-f _pv-mob-100">
        <div className="container">
          <div className="theme-coming-soon">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="theme-coming-soon-header">
                  <h1 className="theme-coming-soon-title">
                    Meet <b>Camino Bus</b>
                  </h1>
                  <p className="theme-coming-soon-subtitle">
                    Reliable transportation to the Camino
                  </p>
                </div>
                <div className="row">
                  <div
                    style={{
                      margin: "50px auto 15px auto",
                      fontSize: "24px",
                      textAlign: "center",
                      maxWidth: "500px"
                    }}
                  >
                    {isSubscribed ? (
                      <div>
                        {
                          "Thank you! We will send you an email when this route is available <3"
                        }
                        <Link
                          style={{
                            marginTop: "30px"
                          }}
                          className="btn btn-primary-inverse btn-block theme-coming-soon-form-btn"
                        >
                          Go home
                        </Link>
                      </div>
                    ) : (
                      <div
                        style={{
                          padding: "0 10px"
                        }}
                      >
                        We are excited to announce that this route is coming
                        soon!
                        <p
                          style={{
                            fontSize: "14px",
                            marginTop: "30px"
                          }}
                        >
                          Would you like us to send an email when this route
                          becomes available?
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-8 col-md-offset-2">
                    <div className="theme-coming-soon-form">
                      <div className="row row-no-gutter">
                        {!isSubscribed && (
                          <div
                            onClick={onSubscribe}
                            className="btn btn-primary-inverse btn-block theme-coming-soon-form-btn"
                          >
                            Notify me!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="theme-social-list">
        <li>
          <a className="fa" href="https://business.facebook.com/thecaminobus">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </li>
        <li>
          <a className="fa" href="https://twitter.com/caminobus">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        <li>
          <a className="fa" href="https://www.instagram.com/camino.bus/">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </li>
      </ul>
      <Link className="theme-abs-logo" to="/">
        <img src={logo} alt="Camino Bus Logo" title="CaminoBus.com" />
      </Link>
    </div>
  );
};

export default Book;
