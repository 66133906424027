import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBusAlt } from "@fortawesome/free-solid-svg-icons";
import caminoArrow from "../../images/caminoarrow.png";
import moment from "moment";
import { Link } from "react-router-dom";

const SearchResult = ({
  departureTime,
  arrivalTime,
  departureLocation,
  departureLocationLabel,
  arrivalLocation,
  arrivalLocationLabel,
  price,
  numPassengers,
  currentUser,
  disableAnalytics,
  isSoldOut = true
}) => {
  const pricePerPassenger = Math.round(price / (numPassengers || 1));
  const departureDate = departureTime.format("ll");
  const departureHour = departureTime.format("h:mm");
  const departureAmPm = departureTime.format("a");
  const arrivalDate = arrivalTime.format("ll");
  const arrivalHour = arrivalTime.format("h:mm");
  const arrivalAmPm = arrivalTime.format("a");
  const tripDurationHours = Math.floor(
    moment.duration(arrivalTime.diff(departureTime)).asHours()
  );
  const tripDurationMinutes =
    moment.duration(arrivalTime.diff(departureTime)).asMinutes() -
    60 * tripDurationHours;
  return (
    <div style={{
      position: 'relative'
    }}>
    {isSoldOut ? (
        <div style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: '0',
          top: '0',
          zIndex: '2',
        }} />
      ) : null}
      <Link
        style={{
          color: '#000',
          textDecoration: 'none',
          display: 'block',
          pointerEvents: isSoldOut ? 'none' : 'default',
          cursor: isSoldOut ? 'default' : 'pointer'
        }}
        to={{
          pathname: "/book",
          state: {
            departureTime: departureTime.unix(),
            arrivalTime: arrivalTime.unix(),
            departureLocation,
            arrivalLocation,
            pricePerPassenger,
            numPassengers,
            currentUser,
            disableAnalytics
          }
        }}
      >
        <div className="theme-search-results-item _mb-10 theme-search-results-item-rounded theme-search-results-item-">
          <div className="theme-search-results-item-preview">
            <div className="theme-search-results-item-mask-link" />
            <div className="row" data-gutter="20">
              <div className="col-md-10 ">
                <div className="theme-search-results-item-flight-sections">
                  <div className="theme-search-results-item-flight-section">
                    <div className="row row-no-gutter row-eq-height">
                      <div className="col-md-2 ">
                        <div className="theme-search-results-item-flight-section-airline-logo-wrap">
                          <img
                            className="theme-search-results-item-flight-section-airline-logo"
                            src={caminoArrow}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-10 ">
                        <div className="theme-search-results-item-flight-section-item">
                          <div className="row">
                            <div className="col-md-3 ">
                              <div className="theme-search-results-item-flight-section-meta">
                                <p className="theme-search-results-item-flight-section-meta-time">
                                  {departureHour}
                                  <span>{departureAmPm}</span>
                                </p>
                                <p className="theme-search-results-item-flight-section-meta-city">
                                  {departureLocationLabel}
                                </p>
                                <p className="theme-search-results-item-flight-section-meta-date">
                                  {departureDate}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="theme-search-results-item-flight-section-path">
                                <div className="theme-search-results-item-flight-section-path-fly-time">
                                  <p>
                                    {tripDurationHours}h {tripDurationMinutes}m
                                    (nonstop)
                                  </p>
                                </div>
                                <div className="theme-search-results-item-flight-section-path-line" />
                                <div className="theme-search-results-item-flight-section-path-line-start">
                                  <FontAwesomeIcon
                                    icon={faBusAlt}
                                    className="theme-search-results-item-flight-section-path-icon"
                                  />
                                  <div className="theme-search-results-item-flight-section-path-line-dot" />
                                  <div className="theme-search-results-item-flight-section-path-line-title">
                                    {departureLocationLabel}
                                  </div>
                                </div>
                                <div className="theme-search-results-item-flight-section-path-line-end">
                                  <FontAwesomeIcon
                                    icon={faBusAlt}
                                    className="theme-search-results-item-flight-section-path-icon"
                                  />
                                  <div className="theme-search-results-item-flight-section-path-line-dot" />
                                  <div className="theme-search-results-item-flight-section-path-line-title">
                                    {arrivalLocationLabel}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 ">
                              <div className="theme-search-results-item-flight-section-meta">
                                <p className="theme-search-results-item-flight-section-meta-time">
                                  {arrivalHour}
                                  <span>{arrivalAmPm}</span>
                                </p>
                                <p className="theme-search-results-item-flight-section-meta-city">
                                  {arrivalLocationLabel}
                                </p>
                                <p className="theme-search-results-item-flight-section-meta-date">
                                  {arrivalDate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 ">
                <div className="theme-search-results-item-book">
                  <div className="theme-search-results-item-price">
                    <p className="theme-search-results-item-price-tag">
                      €{Math.ceil(pricePerPassenger*.89)}
                    </p>
                    <p className="theme-search-results-item-price-sign">
                      per person
                    </p>
                  </div>
                  <div className="btn btn-primary-inverse btn-block theme-search-results-item-price-btn">
                    {isSoldOut ? 'Sold out' : 'Book Now'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SearchResult;
