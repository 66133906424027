import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import {
  faFacebook,
  faInstagram,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div id="mainFooter" className="theme-copyright">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p className="theme-copyright-text">
              Copyright &copy; 2019 &nbsp;
              <Link to="/">CaminoBus.com</Link>. All rights
              reserved.
            </p>
          </div>
          <div className="col-md-6">
            <ul className="theme-copyright-social">
              <li>
                <a href="https://business.facebook.com/thecaminobus">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/caminobus">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/camino.bus/">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;