import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'

const TimeFilter = ({ timeFilters, setTimeFilters, title }) => {
  const handleTimeFilterChanger = timeFilter => {
    setTimeFilters({ ...timeFilters, [timeFilter]: !timeFilters[timeFilter] })
  }
  return (
    <div className="theme-search-results-sidebar-section">
      <h5 className="theme-search-results-sidebar-section-title">
        {title}
      </h5>
      <div className="theme-search-results-sidebar-section-checkbox-list">
        <div className="theme-search-results-sidebar-section-checkbox-list-items">
          <div className="checkbox theme-search-results-sidebar-section-checkbox-list-item">
            <label onClick={() => handleTimeFilterChanger('morning')} className="icheck-label">
              <FontAwesomeIcon icon={timeFilters.morning ? faCheckSquare : faSquare} className="icheck" />
              <span className="icheck-title">
                Morning
                <span className="icheck-sub-title">4:00am - 11:59am</span>
              </span>
            </label>
          </div>
          <div className="checkbox theme-search-results-sidebar-section-checkbox-list-item">
            <label onClick={() => handleTimeFilterChanger('afternoon')} className="icheck-label">
              <FontAwesomeIcon icon={timeFilters.afternoon ? faCheckSquare : faSquare} className="icheck" />
              <span className="icheck-title">
                Afternoon
                <span className="icheck-sub-title">12:00pm - 6:59pm</span>
              </span>
            </label>
          </div>
          <div className="checkbox theme-search-results-sidebar-section-checkbox-list-item">
            <label onClick={() => handleTimeFilterChanger('evening')} className="icheck-label">
              <FontAwesomeIcon icon={timeFilters.evening ? faCheckSquare : faSquare} className="icheck" />
              <span className="icheck-title">
                Evening
                <span className="icheck-sub-title">7:00pm - 3:59am</span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeFilter;
