import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const LocationSelector = ({ locations, selectedLocation, setSelectedLocation, placeholder }) => {
  return (
    <Typeahead
      id="arrivals"
      placeholder={placeholder}
      className="theme-search-area-section-container"
      selected={selectedLocation ? [selectedLocation.label] : []}
      onChange={text => {
        const newSelectedLocationId = text[0] ? text[0].id : false;
        if (newSelectedLocationId) {
          const newSelectedLocation = locations.find(l => l.id === newSelectedLocationId)
          setSelectedLocation(newSelectedLocation)}
        }
      }
      options={locations}
    />
  );
};

export default LocationSelector;
