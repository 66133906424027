import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import moment from "moment";
import ReactGA from 'react-ga';
import FullStory, { FullStoryAPI } from 'react-fullstory';
import Amplify, { Auth, API } from "aws-amplify";
import Home from "./pages/Home";
import Search from "./pages/Search";
import Authentication from "./pages/Authentication";
import awsconfig from "./aws-exports";
import Login from "./components/authentication/Login";
import Logout from "./components/authentication/Logout";
import Register from "./components/authentication/Register";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import Trips from "./pages/Trips";
import Book from "./pages/Book";
import ThankYou from "./pages/ThankYou";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";

Amplify.configure(awsconfig);
API.configure(awsconfig);

function App() {
  const [currentUser, _setCurrentUser] = useState(null);
  const [disableAnalytics, setDisableAnalytics] = useState(false);

  const getDisableAnalytics = urlParam => {
    const localStorageDisableAnalytics = urlParam == 'true' || localStorage.getItem('disableAnalytics') == 'true';
    localStorage.setItem('disableAnalytics', localStorageDisableAnalytics);
    setDisableAnalytics(localStorageDisableAnalytics);
    return localStorageDisableAnalytics;
  }

  const setCurrentUser = userAttributes => {
    const newCurrentUser = {};
    if (!userAttributes) {
      _setCurrentUser(null);
      return;
    }
    userAttributes.forEach(userAttribute => {
      switch (userAttribute.Name) {
        case "name":
          newCurrentUser.firstName = userAttribute.Value;
          return;
        case "family_name":
          newCurrentUser.lastName = userAttribute.Value;
          return;
        case "email_verified":
          newCurrentUser.isVerified = userAttribute.Value;
          return;
        case "sub":
          newCurrentUser.userId = userAttribute.Value;
          return;
        default:
          newCurrentUser[userAttribute.Name] = userAttribute.Value;
          return;
      }
    });
    if (!disableAnalytics) {
      ReactGA.set({ userId: newCurrentUser.userId });
      ReactGA.event({
        category: 'login',
        action: 'success',
        label: newCurrentUser.userId
      });
      FullStoryAPI('identify', newCurrentUser.userId);
    }
    _setCurrentUser(newCurrentUser);
  };

  useEffect(() => {
    const search = window.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const analyticsDisabled = getDisableAnalytics(params.get('isMe'));

    setDepartureLocation(getDefaultDepartureLocation(params.get('departureLocation')));
    setArrivalLocation(getDefaultArrivalLocation(params.get('arrivalLocation')));
    setDepartureDate(getDefaultDepartureDate());
    setNumPassengers(getDefaultNumPassengers());

    if (!analyticsDisabled) {
      ReactGA.initialize('UA-145854290-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        Auth.userAttributes(user).then(userAttributes => {
          setCurrentUser(userAttributes);
        });
      })
      .catch(err => console.log(err));
  }, []);
  const locations = [
    {
      id: "madrid",
      label: "Madrid (Puerta del Sol)",
      routes: [
        { destination: "mad", price: 10, duration: 21 },
        { destination: "sarria", price: 35, duration: 320 },
        { destination: "santiago", price: 40, duration: 429 },
        { destination: "fin", price: 48, duration: 517 }
      ]
    },
    {
      id: "mad",
      label: "Madrid Airport (MAD)",
      routes: [
        { destination: "sarria", price: 35, duration: 289 },
        { destination: "santiago", price: 40, duration: 397 },
        { destination: "fin", price: 48, duration: 486 },
        { destination: "madrid", price: 10, duration: 21 }
      ]
    },
    {
      id: "sarria",
      label: "Sarria",
      routes: [
        { destination: "santiago", price: 15, duration: 96 },
        { destination: "fin", price: 21, duration: 187 },
        { destination: "mad", price: 38, duration: 283 },
        { destination: "madrid", price: 38, duration: 283 },
      ]
    },
    { id: "santiago", label: "Santiago de Compostela", routes: [
      { destination: "sarria", price: 10, duration: 98 },
      { destination: "fin", price: 10, duration: 75 },
      { destination: "mad", price: 40, duration: 342 },
      { destination: "madrid", price: 40, duration: 342 }
    ] },
    {
      id: "fin",
      label: "Finisterre",
      routes: [
        { destination: "santiago", price: 10, duration: 82 },
        { destination: "mad", price: 45, duration: 427 },
        { destination: "madrid", price: 45, duration: 427 },
        { destination: "sarria", price: 18, duration: 400 }
      ]
    },
  ];

  const getDefaultDepartureLocation = urlParam => {
    const departureId = urlParam || localStorage.getItem('departureLocation');
    if (!departureId) {
      return null;
    }
    return locations.find(location => location.id === departureId);
  }

  const getDefaultArrivalLocation = urlParam => {
    const arrivalId = urlParam || localStorage.getItem('arrivalLocation');
    if (!arrivalId) {
      return null;
    }
    return locations.find(location => location.id === arrivalId);
  }

  const getDefaultDepartureDate = () => {
    const localStorageDepartureDate = localStorage.getItem('departureDate');
    return localStorageDepartureDate ? moment(localStorageDepartureDate) : moment();
  }

  const getDefaultNumPassengers = () => {
    const localStorageNumPassengers = localStorage.getItem('numPassengers');
    return localStorageNumPassengers ? localStorageNumPassengers : 1;
  }

  const [departureLocation, setDepartureLocation] = useState(null);
  const [arrivalLocation, setArrivalLocation] = useState(null);
  const [departureDate, setDepartureDate] = useState(moment());
  const [numPassengers, setNumPassengers] = useState(1);

  return (
    <Router>
      {!disableAnalytics && (
        <FullStory org={'NHF7N'} debug={false} namespace='FS' host='fullstory.com' />
      )}
      <Route
        exact
        path="/index.html"
        render={() => (
          <Home
            departureLocation={departureLocation}
            setDepartureLocation={setDepartureLocation}
            arrivalLocation={arrivalLocation}
            setArrivalLocation={setArrivalLocation}
            departureDate={departureDate}
            setDepartureDate={setDepartureDate}
            numPassengers={numPassengers}
            setNumPassengers={setNumPassengers}
            locations={locations}
            currentUser={currentUser}
          />
        )}
      />
      <Route
        exact
        path="/"
        render={() => (
          <Home
            departureLocation={departureLocation}
            setDepartureLocation={setDepartureLocation}
            arrivalLocation={arrivalLocation}
            setArrivalLocation={setArrivalLocation}
            departureDate={departureDate}
            setDepartureDate={setDepartureDate}
            numPassengers={numPassengers}
            setNumPassengers={setNumPassengers}
            locations={locations}
            currentUser={currentUser}
          />
        )}
      />
      <Route
        exact
        path="/search"
        render={() => (
          <Search
            currentUser={currentUser}
            departureLocation={departureLocation}
            setDepartureLocation={setDepartureLocation}
            arrivalLocation={arrivalLocation}
            setArrivalLocation={setArrivalLocation}
            departureDate={departureDate}
            setDepartureDate={setDepartureDate}
            numPassengers={numPassengers}
            setNumPassengers={setNumPassengers}
            locations={locations}
            currentUser={currentUser}
            disableAnalytics={disableAnalytics}
          />
        )}
      />
      <Route
        exact
        path="/book"
        component={Book}
      />
      <Route
        exact
        path="/about"
        render={() => <About currentUser={currentUser} currentPage="about" />}
      />
      <Route
        exact
        path="/contact"
        render={() => (
          <Contact currentUser={currentUser} currentPage="contact" />
        )}
      />
      <Route
        exact
        path="/terms"
        render={() => (
          <TermsOfUse currentUser={currentUser} currentPage="terms" />
        )}
      />
      <Route
        exact
        path="/privacy"
        render={() => (
          <PrivacyPolicy currentUser={currentUser} currentPage="privacy" />
        )}
      />
      <Route
        exact
        path="/profile"
        render={() => (
          <Profile
            currentUser={currentUser}
            currentPage="profile"
          />
        )}
      />
      <Route
        exact
        path="/trips"
        render={() => (
          <Trips
            currentUser={currentUser}
            currentPage="trips"
          />
        )}
      />
      <Route
        exact
        path="/login"
        render={() => (
          <Authentication
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            currentPage="login"
            AuthScreen={Login}
          />
        )}
      />
      <Route
        exact
        path="/register"
        render={() => (
          <Authentication
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            currentPage="register"
            AuthScreen={Register}
            disableAnalytics={disableAnalytics}
          />
        )}
      />
      <Route
        exact
        path="/logout"
        render={() => (
          <Authentication
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            currentPage="logout"
            AuthScreen={Logout}
          />
        )}
      />
      <Route
        exact
        path="/thankyou"
        render={() => (
          <ThankYou
            currentUser={currentUser}
            currentPage="thankyou"
            disableAnalytics={disableAnalytics}
          />
        )}
      />
    </Router>
  );
}

export default App;
